'use strict'

###*
 # @ngdoc object
 # @name mundoBranding
 # @description

###
angular
  .module 'mundoBranding', []
